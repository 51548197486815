import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as highlightsxqIDtGMklEMeta } from "/vercel/path0/pages/highlights.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91item_93VS61Z44hIgMeta } from "/vercel/path0/pages/packages/[item].vue?macro=true";
import { default as index1us1AaJFa5Meta } from "/vercel/path0/pages/packages/index.vue?macro=true";
import { default as reviewsp0OUKSelxuMeta } from "/vercel/path0/pages/reviews.vue?macro=true";
import { default as _91item_93bm9CCcgfkyMeta } from "/vercel/path0/pages/rooms/[item].vue?macro=true";
import { default as indexshyPbhYt8IMeta } from "/vercel/path0/pages/rooms/index.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: "page___nl",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___en",
    path: "/en/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___de",
    path: "/de/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page___fr",
    path: "/fr/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "contact___nl",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___de",
    path: "/de/kontakt",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "faq___nl",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___de",
    path: "/de/faq",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___fr",
    path: "/fr/faq",
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "gallery___nl",
    path: "/galerij",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___en",
    path: "/en/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___de",
    path: "/de/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "gallery___fr",
    path: "/fr/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "highlights___nl",
    path: "/locatie",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "highlights___en",
    path: "/en/location",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "highlights___de",
    path: "/de/standort",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "highlights___fr",
    path: "/fr/site",
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages-item___nl",
    path: "/arrangementen/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages-item___en",
    path: "/en/special-offers/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages-item___de",
    path: "/de/arrangements/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages-item___fr",
    path: "/fr/arrangements/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: "packages___nl",
    path: "/arrangementen",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages___en",
    path: "/en/special-offers",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages___de",
    path: "/de/arrangements",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "packages___fr",
    path: "/fr/arrangements",
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: "reviews___nl",
    path: "/beoordelingen",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___en",
    path: "/en/reviews",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___de",
    path: "/de/rezensionen",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___fr",
    path: "/fr/revues",
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "rooms-item___nl",
    path: "/kamers/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue").then(m => m.default || m)
  },
  {
    name: "rooms-item___en",
    path: "/en/rooms/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue").then(m => m.default || m)
  },
  {
    name: "rooms-item___de",
    path: "/de/zimmer/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue").then(m => m.default || m)
  },
  {
    name: "rooms-item___fr",
    path: "/fr/chambres/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue").then(m => m.default || m)
  },
  {
    name: "rooms___nl",
    path: "/kamers",
    component: () => import("/vercel/path0/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: "rooms___en",
    path: "/en/rooms",
    component: () => import("/vercel/path0/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: "rooms___de",
    path: "/de/zimmer",
    component: () => import("/vercel/path0/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: "rooms___fr",
    path: "/fr/chambres",
    component: () => import("/vercel/path0/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___nl",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___de",
    path: "/de/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___fr",
    path: "/fr/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  }
]